.komorka {
  width: 50px;
  height: 50px;
  display: inline-block;
}

.sciana {
  background-color: black;
}

.ścieżka {
  background-color: #eee;
  /* Jasny kolor dla ścieżek */
  /* background-image: url('../../public/images/sciezka.png');
  background-size: cover;
  background-position: center; */
}


.nieaktywna {
  /* background-color: black; /* or any other color of your choice */
  /* pointer-events: none; this will make the cell non-interactive */
  background-color: #333;
  /* ciemny kolor tła dla efektu ściany */
  border: 1px solid #666;
  /* jaśniejsze krawędzie dla efektu trójwymiarowego */
  pointer-events: none;
  /* komórka nie będzie interaktywna */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  /* wewnętrzny cień dla głębi */
  color: transparent;
  /* tekst w komórce nieaktywnej będzie niewidoczny */
  text-shadow: none;
  /* usuwa ewentualne cienie tekstu */
  /* 
  background-image: url('../../public/images/drzewo.png');
  background-size: cover;
  background-position: center; */

}

.gracz {
  background-image: url('../../public/images/mikolaj.png');
  background-size: cover;
  background-position: center;
}


.meta {
  /* background-color: red; */
  background-image: url('../../public/images/meta.png');
  background-size: cover;
  background-position: center;
}

.komorka.prezent {
  background-image: url('../../public/images/prezent.png');
  background-size: cover;
  background-position: center;
  position: relative;
}

.komorka.pulapka {
  background-image: url('../../public/images/bomba.png');
  background-size: cover;
  background-position: center;
  background-color: #4CAF50;
}