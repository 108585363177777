.labirynt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.wiersz {
  display: flex;
}

.komorka {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.komorka.ściana {
  background-color: #333;
}

.komorka.meta {
  background-color: red;
}

.komorka.gracz {
  background-color: #4caf50;
}

.komunikat {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: red;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  z-index: 999;

}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: #fff;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

.punkty {
  color: #45a049;
  margin-bottom: 10px;
}

.timer {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.czasy {
  color: white;
  position: absolute;
  right: 0%;
  top: 0%;
  height: 100%;
  background-color: #0000006f;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 30px;
  z-index: 0;
  font-size: 20px;
}

.czasy__login {
  width: 100%;
  margin-bottom: 30px;
}

.czasy__login a {
  background-color: #45a049;
  color: white;
  border: none;
  text-decoration: none;
  padding: 10px;
}

.ostatnie-czasy {
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.ostatnie-czasy-header {
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.ostatnie-czasy-lista {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.ostatnie-czasy-lista li {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  color: #666;
  font-family: 'Courier New', Courier, monospace;
}

.ostatnie-czasy-lista li:last-child {
  border-bottom: none;
}

.najlepszy-czas {
  color: #4CAF50;
  font-weight: bold;
}

/* Responsive design adjustments for phones */
@media (max-width: 1300px) {
  .czasy {
    padding: 0 15px;
    /* Reduce padding for smaller screens */
    font-size: 18px;
    /* Smaller font size for smaller screens */
  }

  .ostatnie-czasy {
    max-width: 100%;
    /* Make the container take full width on smaller screens */
    box-shadow: none;
    /* Optional: Remove shadow effect on smaller screens */
  }

  .ostatnie-czasy-header,
  .ostatnie-czasy-lista li {
    font-size: 16px;
    /* Smaller font size for smaller screens */
  }

  .ostatnie-czasy-lista li {
    padding: 10px;
    /* Larger padding for better touch targets */
  }
}

.cross-controls {
  display: none;
}


.controls {
  display: none;
}

@media (max-width: 600px) {
  .labirynt {
    padding: 10px;
    width: 100%;
    /* Make sure it doesn't overflow */
    box-sizing: border-box;
    /* Include padding and border in the width */
    margin-bottom: 20px;
    /* Add space between maze and scoreboard */
  }

  .plansza {
    width: 95vw;
  }

  .wiersz {

    width: 100%;
    flex-wrap: nowrap;
    /* Prevent the rows from wrapping */
  }

  .komorka {

    width: 100%;
    max-width: 30px;
    max-height: 30px;

  }

  .komunikat {
    padding: 10px;
    font-size: 12px;
    /* Reduce font size for smaller screens */
  }

  button {
    font-size: 14px;
    padding: 5px 10px;
  }

  .timer,
  .punkty,
  .ostatnie-czasy-header,
  .ostatnie-czasy-lista li {
    font-size: 14px;
    /* Adjust font size for readability */
  }

  .czasy {
    position: static;
    /* Make it flow in the document */
    background-color: transparent;
    color: #333;
    padding: 0;
    /* Remove padding */
    width: auto;
    /* Allow it to take natural width */
  }

  .ostatnie-czasy {
    background-color: #f0f0f0;
    /* Light background for contrast */
    padding: 10px;
    /* Adjust padding */
    margin-top: 10px;
    /* Adjust margin */
  }

  .cross-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* Adjust the space between buttons as needed */
    height: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    /* Full height of the container */
  }

  .cross-controls .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* Adjust the space between buttons as needed */
  }

  /* This is if you need a specific button styling */
  .cross-controls button {
    /* Styles for the button */
    padding: 10px;
    /* Adjust padding as needed */
    border: 1px solid #ccc;
    /* Example border */
    background-color: #45a049;
    /* Example background color */
    cursor: pointer;
    margin-top: 0;
  }

  /* If you want the buttons to be bigger or need specific dimensions */
  .cross-controls button {
    min-width: 80px;
    /* Adjust width as needed */
    min-height: 40px;
    /* Adjust height as needed */
    color: white;
  }

}