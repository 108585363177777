/* Login.css */
.login {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.login form {
  display: flex;
  flex-direction: column;
}

.login label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #666;
}

.login input {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  /* Ensures padding doesn't affect overall width */
}

.login button {
  padding: 10px;
  background-color: #5cb85c;
  /* A green shade */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login button:hover {
  background-color: #4cae4c;
  /* A darker green shade for hover state */
}

.login p {
  color: #d9534f;
  /* A red shade for error messages */
  text-align: center;
}

.logi__redirect {
  font-size: 20px;
  margin-bottom: 10px;
}

.guest {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;
}

.logi__redirect p {
  margin: 0;
}

.guest p {
  color: black;
}

.logi__redirect a {
  color: #4cae4c;
  text-decoration: none;
}