/* App.css */
body{
  background-image: url('../public/images/tlo.png');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  width: 100%;

}
#root {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 100vh;

}

h1 {
  font-size: 1.5em;
  position: relative;
  z-index: 1;

  @media (min-width:600px) {
    font-size: 3em;
  }
  width: 100%;
  background-color: #000000a4;
/* padding: 20px 0px; */
margin: 10px;
}
